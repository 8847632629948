import { useMutation } from '@apollo/client'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useRouter } from 'found'
import { useSnackbar } from 'notistack'
import React, { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import LinkBody2 from '../../components/navigation/LinkBody2'
import { RegisterMutationMutation, RegisterMutationMutationVariables } from '../../generated/graphql'
import DH from '../../helpers/DH'
import routes from '../../helpers/routes'
import User from '../../schema/User'

const rnd = Math.floor(Math.random() * 10) + 1 //! The 7 is the number of background images we have

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `${'url(media/images/' + rnd + '.jpg)'}`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
  }
})

export default function RegisterScreen() {
  const { t } = useTranslation()
  const { router } = useRouter()
  const { classes } = useStyles()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [register] = useMutation<RegisterMutationMutation, RegisterMutationMutationVariables>(User.REGISTER)

  const submit = async function (e: MouseEvent) {
    e.preventDefault()
    try {
      const input: RegisterMutationMutationVariables = {
        input: {
          email,
          password,
          name,
        },
      }
      const ret = await register({ variables: input })
      if (ret.data && ret.data.createUser.id) {
        router.push(routes.login)
      } else {
        enqueueSnackbar(t('unknown_error'), { variant: 'error' })
      }
    } catch (ex) {
      enqueueSnackbar(DH.getGraphqlErrorMessages(ex).join('<br>'), { variant: 'error' })
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {t('Register')}
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              autoFocus
              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              label={t('Name')}
              name="name"
              autoComplete="name"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              autoFocus
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('Email Address')}
              name="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('Password')}
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant="contained"
              type="submit"
              fullWidth
              color="primary"
              className={classes.submit}
              onClick={submit}
              disabled={!email || !password}
            >
              {t('Sign in')}
            </Button>
            <Grid container>
              <Grid item xs>
                <LinkBody2 to={routes.login}>{t('Already have an account?')}</LinkBody2>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}
