import { useMutation } from '@apollo/client'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useRouter } from 'found'
import { useSnackbar } from 'notistack'
import React, { MouseEvent, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import PasswordResetForm from './PasswordResetForm'
import Loading from '../../components/Loading'
import Link from '../../components/navigation/Link'
import LinkButton from '../../components/navigation/LinkButton'
import { ValidateEmailMutation, ValidateEmailMutationVariables } from '../../generated/graphql'
import routes from '../../helpers/routes'
import log from '../../log'
import Profiles from '../../schema/Profiles'

const rnd = Math.floor(Math.random() * 10) + 1 //! The 7 is the number of background images we have

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `${'url(media/images/' + rnd + '.jpg)'}`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    result: {
      margin: theme.spacing(2),
    },
  }
})

const EmailValidationScreen: React.FC = function EmailValidationScreen() {
  const [validateEmail, { called, data, error, loading }] = useMutation<
    ValidateEmailMutation,
    ValidateEmailMutationVariables
  >(Profiles.VALIDATE_USER_EMAIL)
  const { match } = useRouter()
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [token, setToken] = useState<string>('')
  const { t } = useTranslation()
  const textInput = useRef<HTMLInputElement>(null)

  const { validationToken } = match.params
  if (validationToken && !called) {
    validateEmail({ variables: { input: validationToken } }).catch((ex) => {
      log.error(ex)
    })
  }

  const submit = async function (event: MouseEvent) {
    event.preventDefault()
    try {
      if (token) {
        await validateEmail({ variables: { input: token } })
      }
    } catch (ex) {
      log.error(ex)
      enqueueSnackbar(t('An error has occurred. Try again later or request another validation token.'), {
        variant: 'error',
      })
      textInput.current?.select()
    }
  }

  const validationForm = (
    <form className={classes.form} noValidate autoComplete="off">
      <TextField
        autoFocus
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="token"
        label={t('Validation Token')}
        name="token"
        onChange={(e) => setToken(e.target.value)}
        value={token}
        inputRef={textInput}
      />
      <Button type="submit" fullWidth color="primary" className={classes.submit} onClick={submit} disabled={!token}>
        {t('Validate email')}
      </Button>
    </form>
  )
  const validationFormBody = loading ? (
    <Loading />
  ) : data?.validateEmail?.email ? (
    <>
      <Typography className={classes.result} variant="body1">
        {t('Your email "{{email}}" has been successfully validated', { email: data.validateEmail.email })}
      </Typography>
      {data.validateEmail.setPasswordToken ? (
        <>
          <Typography variant="body1">
            {t(
              "It looks like your account doesn't have a password configured. Please, enter your desired password in the form below:",
            )}
          </Typography>
          <PasswordResetForm token={data.validateEmail.setPasswordToken} />
        </>
      ) : (
        <Typography className={classes.result} variant="body2">
          <Trans i18nKey="forgotten-password-reminder-after-validation">
            {
              "If you haven't set your password yet or can't remember the password set during the registration process you can assign a new one through "
            }
            <Link to={routes.forgot_password}>{'the forgotten password form'}</Link>
            {'.'}
          </Trans>
        </Typography>
      )}
    </>
  ) : error && validationToken ? (
    <Typography className={classes.result} component="h4" variant="subtitle1">
      {t('An error has occurred. Try again later or request another validation token.')}
    </Typography>
  ) : (
    validationForm
  )

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {t('Email Validation')}
          </Typography>
          {validationFormBody}
          <Grid container>
            <Grid item xs>
              <LinkButton to={routes.login}>{t('Go back to login')}</LinkButton>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default EmailValidationScreen
