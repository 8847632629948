import { TextField } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import StaticRecurrenceDatesTable from './StaticRecurrenceDatesTable'
import { EventRecurrenceDataFragment, RecurrenceInput, RecurrenceType } from '../../generated/graphql'

interface OriginalEventData {
  id?: string
  recurrence?: EventRecurrenceDataFragment
  start?: number
}

interface RecurrenceInputFormProps {
  allDay?: boolean
  allowStatic?: boolean
  date?: number
  onChange: (input: RecurrenceInput) => void
  originalEvent?: OriginalEventData
  value: RecurrenceInput
}

function defaultRecurrence(type: RecurrenceType): RecurrenceInput {
  switch (type) {
    case RecurrenceType.No_Recurrence:
      return {
        occurrences: null,
        rrule: null,
        type,
      }
    case RecurrenceType.Static_Recurrence:
      return {
        occurrences: [],
        rrule: null,
        type,
      }
    case RecurrenceType.RRule_Recurrence:
      return {
        occurrences: null,
        rrule: '',
        type,
      }
  }
}

export default function RecurrenceInputForm(props: RecurrenceInputFormProps): ReactElement {
  const { allDay = false, allowStatic = true, date = Math.trunc(Date.now() / 1000), onChange, originalEvent } = props

  const { t } = useTranslation()

  const originalRecurrence = originalEvent?.recurrence
  const recurrenceInput = props.value ?? originalRecurrence ?? defaultRecurrence(RecurrenceType.No_Recurrence)

  const recurrenceTypesList = useMemo(() => {
    return Object.values(RecurrenceType).filter((type) => {
      return (allowStatic || type !== RecurrenceType.Static_Recurrence) && type !== RecurrenceType.RRule_Recurrence
      // return type !== RecurrenceType.RRule_Recurrence

    })
  }, [allowStatic])

  const recurrenceStart = originalRecurrence?.start ?? date
  const occurrencesList =
    recurrenceInput?.occurrences?.filter(({ id }) => !originalEvent?.id || id !== originalEvent?.id) ?? []
  const recurrenceType = recurrenceInput?.type ?? RecurrenceType.No_Recurrence

  const onChangeSelectedType = useCallback(
    (event) => {
      const selectedType = event.target.value as RecurrenceType
      if (selectedType !== recurrenceType) {
        if (selectedType === originalRecurrence?.type) {
          onChange(originalRecurrence)
        } else {
          onChange(defaultRecurrence(selectedType))
        }
      }
    },
    [onChange, originalRecurrence, recurrenceType],
  )

  return (
    <>
      <TextField
        select
        variant="standard"
        label={t('Recurrence Type')}
        disabled={!!occurrencesList.length}
        fullWidth
        inputProps={{
          name: 'recurrence-type-id',
          id: 'recurrence-type-id',
        }}
        onChange={onChangeSelectedType}
        value={recurrenceType}
      >
        {recurrenceTypesList.map((type: string) => {
          return (
            <MenuItem key={type} value={type}>
              <ListItemText primary={t(type)} />
            </MenuItem>
          )
        })}
      </TextField>

      {recurrenceType === RecurrenceType.Static_Recurrence && (
        <StaticRecurrenceDatesTable
          allDay={allDay}
          dates={occurrencesList}
          defaultDate={date}
          minDate={recurrenceStart}
          onEdit={(events) =>
            onChange({
              occurrences: events,
              type: recurrenceType,
            })
          }
        />
      )}
    </>
  )
}
