import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useRouter } from 'found'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import PasswordResetForm from './PasswordResetForm'
import LinkButton from '../../components/navigation/LinkButton'
import routes from '../../helpers/routes'

const rnd = Math.floor(Math.random() * 10) + 1 //! The 7 is the number of background images we have

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `${'url(media/images/' + rnd + '.jpg)'}`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }
})

export default function PasswordResetScreen() {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { match } = useRouter()
  const { token } = match.params

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {t('Reset Password')}
          </Typography>
          {token && <PasswordResetForm token={token} />}
          <Grid container>
            <Grid item xs>
              <LinkButton to={routes.login}>{t('Go back to login')}</LinkButton>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}
