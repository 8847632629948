import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { SnackbarProvider } from 'notistack'
import React, { Suspense, useEffect, useState } from 'react'
import MainRouter from './MainRouter'
import Loading from './components/Loading'
import Auth from './helpers/auth'
import i18n, { i18nHelper } from './i18n'
import { BranchProvider } from './providers/BranchContext'
import { CalendarProvider } from './providers/CalendarContext'
import { CookieProvider } from './providers/CookiesContext'
import { UserProvider } from './providers/UserContext'
import theme from '../src/components/theme'
import './index.css'
import './App.css'

const App: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [locale, setLocale] = useState('en')
  useEffect(() => {
    const init = async () => {
      const localeString = i18n.language
      dayjs.locale(localeString)
      setLocale(localeString)
      await Auth.initClientCache()
      setLoading(false)
    }
    // noinspection JSIgnoredPromiseFromCall
    init()
  }, [])
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Loading />
      </ThemeProvider>
    )
  }
  return (
    <ApolloProvider client={Auth.getClient()}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18nHelper.getLocale(locale)}>
        <Suspense fallback={<Loading />}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <UserProvider>
                <BranchProvider>
                  {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                  <CssBaseline />
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <CalendarProvider>
                        <div className="App">
                          <CookieProvider />
                          <MainRouter />
                        </div>
                    </CalendarProvider>
                  </SnackbarProvider>
                </BranchProvider>
              </UserProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </Suspense>
      </LocalizationProvider>
    </ApolloProvider>
  )
}

export default App
