import { useMutation } from '@apollo/client'
import { CssBaseline, Grid, Paper, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { useRouter } from 'found'
import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Loading from '../../components/Loading'
import { TimeZones } from '../../helpers/Locale'
import i18n from '../../i18n'
import log from '../../log'
import EventAttendees from '../../schema/EventAttendees'

const rnd = Math.floor(Math.random() * 10) + 1 //! The 7 is the number of background images we have

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `${'url(media/images/' + rnd + '.jpg)'}`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    selectorCss: {
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    result: {
      margin: theme.spacing(2),
    },
    warningText: {
      backgroundColor: 'rgb(255, 244, 229)',
      borderRadius: '4px',
      color: 'rgb(102, 60, 0)',
      margin: theme.spacing(3),
      padding: '6px 16px',
    },
  }
})

export default function InvitePacientResponseScreen() {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { match } = useRouter()
  const { tokenRecieved } = match.params

  const [tokenError, setTokenError] = useState()

  const [language, setLanguage] = React.useState('en-GB')

  const [decodeAndFindLocale] = useMutation(EventAttendees.INVITE_ATTENDEE_LOCALE_GET)
  useEffect(() => {
    i18n.changeLanguage(language, (err) => {
      if (err) {
        console.error('changing language error: ', err)
      }
    })
  }, [language])

  useEffect(() => {
    decodeAndFindLocale({
      variables: { input: { token: tokenRecieved, actionResponse: 'locale' } },
    })
      .catch(setTokenError)
      .then((response: any) => {
        const responseRecieved = response.data.decodeAndFindLocale.response
        setLanguage(responseRecieved)
      })
  }, [tokenRecieved, decodeAndFindLocale])

  const [decodeAndUpdateResponse, { called, data, error, loading }] = useMutation(
    EventAttendees.INVITE_ATTENDEE_RESPONSE,
  )

  const submit = useCallback(
    (dataParams) =>
      async (event: React.MouseEvent): Promise<void> => {
        event.preventDefault()
        if (tokenRecieved && !called) {
          decodeAndUpdateResponse({
            variables: { input: { token: tokenRecieved, actionResponse: dataParams.actionResponse } },
          }).catch(setTokenError)
        }
      },
    [tokenRecieved, called, decodeAndUpdateResponse],
  )

  const getErrorMessage = useCallback(
    (errorCB) => {
      const message = errorCB.message.replace(/^GraphQL error:\W*/gi, '')
      switch (message) {
        case 'Invalid token':
          return (
            // <Typography className={classes.result} component="h4" variant="subtitle1">
            //   {t('An error has occurred. Try again later or request another invitation.')}
            // </Typography>
            <Typography className={classes.warningText} component="h4" variant="subtitle1">
              <Trans i18nKey="invitePatientTokenInvalid">{t('invite_patient_to_event.invite_invalid_token')}</Trans>
            </Typography>
          )
        case 'Malformed UTF-8 data':
          return (
            <Typography className={classes.warningText} component="h4" variant="subtitle1">
              <Trans i18nKey="invitePatientTokenInvalid">
                {t('invite_patient_to_event.invite_invalid_token_modified')}
              </Trans>
            </Typography>
          )
        case 'Token has expired':
          return (
            <Typography className={classes.warningText} component="h4" variant="subtitle1">
              <Trans i18nKey="invitePatientTokenExpired">{t('invite_patient_to_event.invite_token_expired')}</Trans>
            </Typography>
          )
        case 'Attendee already confirmed':
          return (
            <Typography className={classes.warningText} component="h4" variant="subtitle1">
              <Trans i18nKey="resetPasswordTokenUsed">{t('invite_patient_to_event.invite_already_confirmed')}</Trans>
            </Typography>
          )
        case 'Attendee already decline':
          return (
            <Typography className={classes.warningText} component="h4" variant="subtitle1">
              <Trans i18nKey="resetPasswordTokenUsed">{t('invite_patient_to_event.invite_already_declined')}</Trans>
            </Typography>
          )
        default:
          log.error(errorCB)
          return (
            <Typography className={classes.error} component="h4" variant="subtitle1">
              {t('invite_patient_to_event.invite_error_occured', { error: message || errorCB.messag })}
            </Typography>
          )
      }
    },
    [classes.error, classes.warningText, t],
  )
  if (loading) {
    return <Loading />
  }

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value)
  }

  const validationForm = (
    <form className={classes.form} noValidate autoComplete="off">
      <Typography sx={{ textAlign: 'center', fontSize: 19 }} variant="h5">
        <br />
        {t('invite_patient_to_event.invite_choose_answer')}
      </Typography>
      <Stack spacing={2} direction="row" sx={{ paddingTop: 3 }}>
        <Button
          type="submit"
          fullWidth
          color="primary"
          // className={classes.submit}
          onClick={submit({ actionResponse: 'invite_patient_to_event.accept_invite_patient' })}
          variant="contained"
        >
          {t('invite_patient_to_event.invite_accept_button')}
        </Button>
        <Button
          type="submit"
          fullWidth
          color="primary"
          // className={classes.submit}
          onClick={submit({ actionResponse: 'invite_patient_to_event.decline_invite_patient' })}
          variant="outlined"
        >
          {t('invite_patient_to_event.invite_decline_button')}
        </Button>
      </Stack>
    </form>
  )

  const validationFormBody = loading ? (
    <Loading />
  ) : data?.decodeAndUpdateResponse?.response ? (
    <>
      <Typography className={classes.result} variant="body1">
        {`${
          data?.decodeAndUpdateResponse?.response === 'Ok accepted'
            ? t('invite_patient_to_event.invite_respond_accept_message')
            : data?.decodeAndUpdateResponse?.response === 'Ok declined'
            ? t('invite_patient_to_event.invite_respond_decline_message')
            : t('invite_patient_to_event.invite_unkown_error_happened')
        }`}
      </Typography>
    </>
  ) : tokenError ? (
    getErrorMessage(tokenError)
  ) : error && tokenRecieved ? (
    <Typography className={classes.result} component="h4" variant="subtitle1">
      {t('invite_patient_to_event.invite_error_try_again')}
    </Typography>
  ) : (
    validationForm
  )

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {/* <Grid 
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          > */}
          <FormControl sx={{ m: 1, minWidth: 80 }} className={classes.selectorCss}>
            {/* <InputLabel id="demo-simple-select-autowidth-label">{'Language'}</InputLabel> */}
            <Select
              variant="standard"
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={language}
              onChange={handleChange}
              autoWidth
              label="Language"
            >
              {Object.keys(TimeZones).map((k) => (
                <MenuItem value={TimeZones[k].code} key={k}>
                  {TimeZones[k].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* </Grid> */}

          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              {t('invite_patient_to_event.invite_title_page')}
            </Typography>

            {validationFormBody}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
