import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { useTranslation } from 'react-i18next'

export const CookieProvider: React.FC = () => {
    const { t } = useTranslation()
    const COOKIES_DOCUMENTS = 'media/documents/Cookies_EN.pdf'

    return (
      <CookieConsent
      location="bottom"
      buttonText={t('cookies.ok_button')}
      cookieName="cookieConsent"
      style={{ background: '#FFF', zIndex: 9999, boxShadow: '5px 5px 10px 2px rgba(0,0,0,.8)' }}
      buttonStyle={{
        color: '#1010EB',
        background: 'transparent',
        fontSize: '12px',
        fontWeight: 'bold',
        border: '1px solid #1010EB',
        borderRadius: '2px',
      }}
      debug={false}
      contentStyle={{ color: 'black', fontSize: '13px' }}
      expires={60}
      >
          {t('cookies.description')}{' '} 
          <a href={COOKIES_DOCUMENTS} style={{ color: '#1010EB', fontWeight: 'bold' }}>
          {t('cookies.learn_more')}
        </a>
      </CookieConsent>
    )
}
